import { ReactNode, useEffect, useRef } from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  style,
  color,
  font,
  fontFamily,
  width,
  easing,
  animation,
} from "../utils/styles"

import ReserveButton from "../components/parts/reserve_button"

import { FaPhoneAlt } from "react-icons/fa"
import { IoIosMail } from "react-icons/io"

import IntroLogo from "../img/top/intro_logo.svg"
import IllustHouse from "../img/top/illust_house.svg"
import IllustWalk from "../img/top/illust_walk.svg"
import FacilityNetTitle from "../img/top/facility_net.svg"
import FoodCatch from "../img/top/food_catch_coupon.svg"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Intro />
      <Facility />
      <Access />
      <Price />
      <Contact />
    </Layout>
  )
}

export default IndexPage

const ContentSection = styled.section`
  display: block;
  padding-bottom: 80px;
`

const Intro = () => {
  const IntroTopImage = () => {
    const imgRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const io = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show")
            }
          })
        },
        {
          threshold: [0.5],
        }
      )
      imgRef.current && io.observe(imgRef.current)

      return () => {
        io.disconnect()
      }
    }, [])

    return (
      <div
        css={css`
          padding: min(14vw, 70px) 0;
          margin: -20px 0 20px;
          overflow: hidden;
          position: relative;
          z-index: 10;
        `}
      >
        <div
          css={css`
            ${style.content}
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
          `}
        >
          <div
            ref={imgRef}
            css={css`
              width: 90vw;
              height: 75vw;
              max-width: ${width.content * 0.9}px;
              max-height: ${width.content * 0.8 * 0.62}px;
              ${animation.imgRotateBg}
            `}
          >
            <StaticImage
              src="../img/top/intro_img.jpg"
              alt="ゲストハウス 横分けハンサム"
              css={css`
                width: 100%;
                height: 100%;
              `}
              loading="eager"
              objectPosition="left center"
            />
          </div>
        </div>
      </div>
    )
  }

  const IntroTop = () => {
    return (
      <div
        css={css`
          margin: -120px 0 -160px;
          padding: 160px 0 220px;
          position: relative;
          z-index: 0;
          &::before {
            content: "";
            position: absolute;
            inset: 0;
            width: min(
              calc((100vw - ${width.content}px) / 2 + 0.9 * ${width.content}px),
              96vw
            );
            background: ${color.main};
            z-index: -1;
          }
        `}
      >
        <div
          css={css`
            ${style.content}
          `}
        >
          <div
            css={css`
              width: 90%;
            `}
          >
            <h2
              css={css`
                font-size: min(5vw, 3.2rem);
                font-weight: normal;
                line-height: 1.7;
                color: ${color.white};
              `}
            >
              サクッとスマートに泊まる
              <br />
              全室個室のゲストハウス
            </h2>
            <h1
              css={css`
                margin-top: min(32px, 6.5vw);
                width: 90%;
              `}
            >
              <IntroLogo />
            </h1>
            <div
              css={css`
                margin-top: 64px;
                text-align: center;
              `}
            >
              <IllustHouse />
            </div>
            <div
              css={css`
                margin-top: 40px;
                display: flex;
                justify-content: center;
              `}
            >
              <p
                css={css`
                  color: ${color.white};
                  font-size: max(1.5rem, min(2.4rem, 4.3vw));
                `}
              >
                寝心地のよい広々ベッドに机。
                <br />
                見たいものが見られる大きなテレビ。
                <br />
                プライバシーが確保された個室で、
                <br />
                自分らしい八丈ステイを。
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const IntroRoomImage = () => {
    const imgRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const io = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show")
            }
          })
        },
        {
          threshold: [0.5],
        }
      )
      imgRef.current && io.observe(imgRef.current)

      return () => {
        io.disconnect()
      }
    }, [])

    return (
      <div
        css={css`
          padding: 50px 0;
          overflow: hidden;
          position: relative;
        `}
      >
        <div
          css={css`
            ${style.content}
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
          `}
        >
          <div
            ref={imgRef}
            css={css`
              ${animation.imgRotateBg}
              width: 305px;
              height: 200px;

              width: 90vw;
              height: 60vw;
              max-width: ${width.content * 0.75}px;
              max-height: ${width.content * 0.6 * 0.65}px;
              &::before {
                background: ${color.gray};
              }
            `}
          >
            <StaticImage
              src="../img/top/intro_room.jpg"
              alt=""
              css={css`
                width: 100%;
                height: 100%;
              `}
              objectPosition="left center"
            />
          </div>
        </div>
      </div>
    )
  }

  const IntroRoom = () => {
    return (
      <div
        css={css`
          ${style.contentSlim}
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        `}
      >
        <div>
          <h3
            css={css`
              ${font.h2}
              color: ${color.main};
            `}
          >
            全室個室＆ダブルベッド
          </h3>
          <p
            css={css`
              padding: 16px 0 0 16px;
            `}
          >
            当館は1部屋のツインルームを除き、すべてシングルルームとなっております。
            <br />
            自分の部屋のように寛いでいただけるよう、シンプルで余裕のある空間作りを目指しました。
            <br />
            <br />
            ベッドはすべてダブルサイズ。
            <br />
            広々ベッドにどーんと大の字になって、旅や仕事の疲れを癒してください。
          </p>
        </div>
      </div>
    )
  }

  const IntroFor = () => {
    const elRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const io = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show")
            }
          })
        },
        {
          threshold: [0.5],
        }
      )
      elRef.current && io.observe(elRef.current)

      return () => {
        io.disconnect()
      }
    }, [])

    return (
      <div
        ref={elRef}
        css={css`
          position: relative;
          margin-top: 68px;
          padding: 32px 0 110px;
          overflow: hidden;
          &::before {
            content: "";
            display: block;
            background: ${color.yellow};
            height: min(80%, 130px);
            width: 0;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            transition: width 1.5s ${easing.easeOutQuart};
          }
          &.show::before {
            width: min(
              calc((100vw - ${width.content}px) / 2 + 0.6 * ${width.content}px),
              60vw
            );
          }
        `}
      >
        <IllustWalk
          css={css`
            position: absolute;
            left: 70vw;
            bottom: 0;
          `}
        />
        <div
          css={css`
            ${style.contentSlim}
          `}
        >
          <h3
            css={css`
              ${font.h2}
              color: ${color.main};
            `}
          >
            旅行にも、仕事にも。
          </h3>
          <p
            css={css`
              padding-top: 16px;
              max-width: 65vw;
              color: ${color.main};
            `}
          >
            ビジネスでの長期利用にお得な連泊プランもご用意しています。
            <br />
            観光に、出張に、ワーケーションにご利用ください。
          </p>
        </div>
      </div>
    )
  }

  const IntroCTA = () => {
    return (
      <div
        css={css`
          margin-top: 68px;
          ${style.content}
        `}
      >
        <ReserveButton
          css={css`
            margin: 0 auto;
          `}
        />

        <div
          css={css`
            margin: 30px auto 0;
            border: 1px solid ${color.blueL};
            color: ${color.blue};
            padding: min(20px, 2vw);
            display: flex;
            width: fit-content;
            max-width: 100%;
            justify-content: space-between;
            font-size: min(2.56rem, 4.26vw);
            a {
              color: ${color.blue};
              text-decoration: none;
            }
          `}
        >
          <div
            css={css`
              text-align: center;
            `}
          >
            <h3
              css={css`
                font-weight: 700;
              `}
            >
              お問合せ
            </h3>
            <p
              css={css`
                font-size: min(1.76rem, 2.93vw);
              `}
            >
              【運営会社】
              <br />
              (株)アクロス
            </p>
          </div>
          <div
            css={css`
              margin-left: min(30px, 4vw);
            `}
          >
            <a
              href="tel:04996-2-1745"
              css={css`
                display: flex;
                align-items: center;
                font-size: min(3.2rem, 5.3vw);
                font-weight: 700;
                .small {
                  font-size: min(1.6rem, 2.6vw);
                }
              `}
            >
              <FaPhoneAlt
                size={17}
                css={css`
                  margin-right: 8px;
                `}
              />
              <span>
                04996-2-1745<span className="small">（平日）</span>
              </span>
            </a>
            <a
              href="tel:080-8705-7717"
              css={css`
                margin-left: 25px;
                margin-top: 5px;
                display: block;
                padding-top: 4px;
                border-top: dashed 1px ${color.blueL};
                font-weight: 700;
                font-size: min(2.56rem, 4.26vw);
                .small {
                  font-size: min(1.6rem, 2.6vw);
                }
              `}
            >
              <span>
                080-8705-7717<span className="small">（土日祝日）</span>
              </span>
            </a>
            <div
              css={css`
                font-size: min(1.92rem, 3.2vw);
                margin-left: 17px;
              `}
            >
              【 受付時間　9:00〜20:00 】
            </div>
            <a
              href="mailto:shukuhaku@across.co.jp"
              css={css`
                display: flex;
                align-items: center;
                margin-top: 5px;
                padding-top: 4px;
                font-size: min(2.24rem, 3.73vw);
                font-weight: 700;
                border-top: 1px solid ${color.blueL};
              `}
            >
              <IoIosMail
                size={19}
                css={css`
                  margin-right: 6px;
                `}
              />
              shukuhaku@across.co.jp
            </a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ContentSection>
      <IntroTopImage />
      <IntroTop />
      <IntroRoomImage />
      <IntroRoom />
      <IntroFor />
      <IntroCTA />
    </ContentSection>
  )
}

type SectionTitleProps = {
  children: ReactNode
  english: string
}
const SectionTitle = ({ children, english }: SectionTitleProps) => {
  return (
    <div>
      <h3
        css={css`
          font-family: ${fontFamily.decorateSans};
          color: ${color.white};
          font-size: 1rem;
          line-height: 1;
          text-align: center;
          padding: 4px 0;
          background: ${color.main};
        `}
      >
        {english}
      </h3>
      <h2
        css={css`
          background: ${color.gray};
          color: ${color.main};
          text-align: center;
          font-weight: bold;
          padding: 8px 0;
        `}
      >
        {children}
      </h2>
    </div>
  )
}

const Facility = () => {
  const ulRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    const io = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show")
          }
        })
      },
      {
        threshold: [0.5],
      }
    )
    ulRef.current && io.observe(ulRef.current)

    return () => {
      io.disconnect()
    }
  }, [])

  const FacilityOverview = () => {
    const ImgLi = styled.li`
      opacity: 0;
      transition: transform 1s ${easing.easeOutQuart},
        opacity 1s ${easing.easeOutSine};
      .show & {
        opacity: 1;
        &:nth-of-type(odd) {
          transform: translateY(-25px);
        }
        &:nth-of-type(even) {
          transform: translateY(25px);
        }
      }
    `
    return (
      <div>
        <p
          css={css`
            ${style.contentText};
            margin-top: 40px;
          `}
        >
          男女別の洗面所には、それぞれトイレとシャワールームが２つずつ設置されており、忙しい朝もゆったりと身支度を整えられます。
          <br />
          ダイニングにはご自由にお使いいただける電子レンジや冷蔵庫、ポットを設置しています。
        </p>
        <ul
          ref={ulRef}
          css={css`
            ${style.content};
            display: flex;
            justify-content: center;
            margin-top: min(56px, 10.95vw);
            padding: 25px 0;
          `}
        >
          <ImgLi>
            <StaticImage src="../img/top/facility_img_01.jpg" alt="" />
          </ImgLi>
          <ImgLi>
            <StaticImage src="../img/top/facility_img_02.jpg" alt="" />
          </ImgLi>
        </ul>
      </div>
    )
  }

  const FacilityNet = () => {
    return (
      <div
        css={css`
          ${style.content}
          text-align: center;
          margin-top: 64px;
        `}
      >
        <FacilityNetTitle
          css={css`
            width: 60%;
          `}
        />
        <p
          css={css`
            font-size: min(2.4rem, 4.38vw);
            font-weight: bold;
            color: ${color.main};
            margin-top: 24px;
          `}
        >
          館内はWi-Fi完備。
          <br />
          全室、インターネットテレビ設置。
        </p>
      </div>
    )
  }

  const FacilityAmenity = () => {
    const AmenityTitle = styled.h4`
      text-align: center;
      font-weight: bold;
      &::before,
      &::after {
        content: "―";
        margin: 0 5px;
      }
      &:nth-of-type(n + 2) {
        margin-top: 32px;
      }
    `

    type AmenityItems = {
      items: string[]
    }
    const AmenityList = ({ items }: AmenityItems) => {
      const listRef = useRef<HTMLUListElement>(null)
      useEffect(() => {
        const ro = new ResizeObserver(() => {
          if (listRef.current) {
            let y = 0
            const li = listRef.current.children
            for (let i = 0; i < li.length; i++) {
              if (y != li[i].getBoundingClientRect().top) {
                li[i].classList.remove("line")
                y = li[i].getBoundingClientRect().top
              } else {
                li[i].classList.add("line")
              }
            }
          }
        })
        listRef.current && ro.observe(listRef.current)

        return () => {
          ro.disconnect()
        }
      }, [])

      return (
        <ul
          ref={listRef}
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 8px;
            padding-right: 1em;
          `}
        >
          {items.map((item: string, i: number) => {
            return (
              <li
                css={css`
                  &::before {
                    content: "　";
                    margin: 0 0.5em;
                  }
                  &.line&::before {
                    content: "｜";
                  }
                `}
                key={i}
              >
                {item}
              </li>
            )
          })}
        </ul>
      )
    }

    return (
      <div
        css={css`
          ${style.content}
          margin-top: 24px;
          padding: 20px;
          background: ${color.gray};
        `}
      >
        <AmenityTitle>アメニティ</AmenityTitle>
        <AmenityList
          items={[
            "スリッパ",
            "歯ブラシ",
            "タオル",
            "パジャマ",
            "髭剃り",
            "綿棒など",
          ]}
        />

        <AmenityTitle>客室設備</AmenityTitle>
        <AmenityList
          items={[
            "テレビ",
            "サーキュレーター",
            "テーブルライト",
            "物干しワイヤー",
            "ベッド用スタンドライト",
            "鏡",
            "充電器",
          ]}
        />
      </div>
    )
  }
  return (
    <ContentSection>
      <SectionTitle english="FACILITY">充実の共通設備</SectionTitle>
      <FacilityOverview />
      <FacilityNet />
      <FacilityAmenity />
    </ContentSection>
  )
}

const Access = () => {
  const Map = () => {
    return (
      <div
        css={css`
          margin-top: 16px;
          position: relative;
          height: 66vw;
          max-height: 400px;
        `}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3341.3866512419136!2d139.8025585186205!3d33.12520528587069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3203f62e301cfc7!2zMzPCsDA3JzMwLjciTiAxMznCsDQ4JzEzLjQiRQ!5e0!3m2!1sja!2sjp!4v1639545520281!5m2!1sja!2sjp"
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            border: 0;
          `}
          loading="lazy"
        ></iframe>
      </div>
    )
  }

  const Points = () => {
    return (
      <div
        css={css`
          ${style.content}
          margin-top: 40px;
          display: flex;
          justify-content: center;
        `}
      >
        <ul
          css={css`
            flex-shrink: 0;
            margin-top: 9px;
            padding-right: 20px;
            font-size: min(1.8rem, 4.4vw);
            & > li {
              :nth-of-type(n + 2) {
                margin-top: 8px;
              }
              &::before {
                content: "・";
              }
            }
          `}
        >
          <li>八丈島空港から車で5分</li>
          <li>底土港から車で4分</li>
          <li>スーパーまで徒歩10分</li>
          <li>飲食店まで徒歩7分</li>
        </ul>
      </div>
    )
  }
  return (
    <ContentSection>
      <SectionTitle english="ACCESS">飲食店やスーパーが徒歩圏内</SectionTitle>
      <Map />
      <Points />
    </ContentSection>
  )
}

const Price = () => {
  const PriceList = () => {
    const Title = styled.h3`
      text-align: center;
      color: ${color.main};
      font-weight: bold;
      font-size: 1.2rem;
      padding: 13px 8px;
      border: solid 1px ${color.main};
      ul + & {
        margin-top: 30px;
      }
    `
    const List = styled.ul`
      margin-top: 10px;
      & > li {
        padding: 10px 0;
        margin: 0 2%;
        border-bottom: solid 1px ${color.gray};
        display: flex;
        &:last-of-type {
          border-bottom: 0px;
        }
      }
    `
    const Label = styled.p`
      flex: 0 0 50%;
      padding: 0 2%;
    `
    const Value = styled.p`
      flex: 0 0 50%;
      padding: 0 2% 0 0;
    `
    return (
      <div
        css={css`
          ${style.contentText}
          width: 92%;
          margin-top: 32px;
        `}
      >
        <Title>大人1泊料金 (素泊まり）</Title>
        <List>
          <li>
            <Label>閑散期</Label>
            <Value>
              1名　6,600円
              <br />
              2名　8,800円
            </Value>
          </li>
          <li>
            <Label>通常期</Label>
            <Value>
              1名　8,800円
              <br />
              2名　1,1000円
            </Value>
          </li>
          <li>
            <Label>繁忙期</Label>
            <Value>
              1名　9,900円
              <br />
              2名　13,200円
            </Value>
          </li>
          <li>
            <Label>最繁忙期</Label>
            <Value>
              1名　13,200円
              <br />
              2名　17,600円
            </Value>
          </li>
        </List>

        <p
          css={css`
            padding-top: 24px;
          `}
        >
          ※お食事のご用意ができる期間もございます。詳しくはメールまたはお電話にてお問合せください。
        </p>
      </div>
    )
  }

  const PriceFood = () => {
    const elmRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const io = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show")
            }
          })
        },
        {
          threshold: [0.5],
        }
      )
      elmRef.current && io.observe(elmRef.current)

      return () => {
        io.disconnect()
      }
    }, [])

    return (
      <div>
        <div
          css={css`
            ${style.contentSlim}
            margin-top: 48px;
          `}
        >
          <FoodCatch />
        </div>
        <div
          ref={elmRef}
          css={css`
            ${style.content}
            width: 100%;
            margin-top: 24px;
            opacity: 0;
            transition: opacity 1s ${easing.easeOutSine};
            &.show {
              opacity: 1;
            }
          `}
        >
          <StaticImage
            css={css`
              width: 100%;
            `}
            src="../img/top/food_img.jpg"
            alt=""
          />
        </div>
        <p
          css={css`
            ${style.contentText}
            margin-top: 24px;
          `}
        >
          ゲストハウス横分けハンサムの運営会社は、八丈島で人気の食堂「藍ヶ江水産」も手掛けています。
          <br />
          お会計の際に宿泊してることがわかる予約のページなどをご提示いただくと、ご飲食代が10％オフ！
        </p>
      </div>
    )
  }

  return (
    <ContentSection>
      <SectionTitle english="PRICE">
        ご利用料金
        <span
          css={css`
            font-size: 1.2rem;
            margin-left: 0.5em;
          `}
        >
          ※税込
        </span>
      </SectionTitle>
      <PriceList />
      <PriceFood />
    </ContentSection>
  )
}

const Contact = () => {
  return (
    <ContentSection id="contact">
      <div
        css={css`
          ${style.content}
        `}
      >
        <ReserveButton
          css={css`
            margin: 0 auto;
          `}
        />
      </div>
    </ContentSection>
  )
}
